import { axiosBaseQuery } from '@client';
import { RequestCaseWizardResponse } from '@module/request/model/RequestCaseWizardResponse';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ExpenseCatalogueItemResponse,
  ExpenseRequestForm,
  MostUsedExpenseItem,
  RequestsWizardExpensesResponse,
  RequestsWizardStepResponse,
  SubmitedRequestsWizardExpensesResponse,
  SubmittedRequestsWizardVesselPortResponse,
  VesselPortRequestForm
} from '@state';

export const buyerDaToolRequestWizardApi = createApi({
  reducerPath: 'buyerDaToolRequestWizard',
  tagTypes: [
    'requestWizardStep1SubmittedVesselPort',
    'requestWizardStep3SubmitedExpenses'
  ],
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    fetchSubmittedVesselPort: build.query<
      SubmittedRequestsWizardVesselPortResponse,
      { requestCaseId: string }
    >({
      query: ({ requestCaseId }) => ({
        url: `/secured/request-case/${requestCaseId}/load-vessel-port-step`,
        method: 'GET'
      }),
      transformResponse: (
        response: SubmittedRequestsWizardVesselPortResponse
      ) => {
        return { ...response, portId: response.portId?.toString() };
      },
      providesTags: ['requestWizardStep1SubmittedVesselPort']
    }),
    saveRequestWizardStep1: build.mutation<
      RequestsWizardStepResponse,
      VesselPortRequestForm
    >({
      query: (data) => ({
        url: '/secured/request-case/save-step-1',
        method: 'POST',
        data
      }),
      invalidatesTags: ['requestWizardStep1SubmittedVesselPort']
    }),
    fetchHusbandyExpenseCatalogues: build.query<
      ExpenseCatalogueItemResponse[],
      void
    >({
      query: () => ({
        url: '/secured/catalogue-expenses/HUSBANDRY_EXPENSES/list',
        method: 'GET'
      })
    }),
    fetchSubmitedExpenses: build.query<
      SubmitedRequestsWizardExpensesResponse,
      { requestCaseId: string }
    >({
      query: ({ requestCaseId }) => ({
        url: `/secured/request-case/${requestCaseId}/load-expenses-step`,
        method: 'GET'
      }),
      providesTags: ['requestWizardStep3SubmitedExpenses']
    }),
    saveRequestWizardStep3: build.mutation<
      RequestsWizardExpensesResponse,
      ExpenseRequestForm
    >({
      query: (expenseData) => ({
        url: '/secured/request-case/save-step-3',
        method: 'POST',
        data: expenseData
      }),
      invalidatesTags: ['requestWizardStep3SubmitedExpenses']
    }),
    fetchPastCaseExpenses: build.query<
      MostUsedExpenseItem[],
      { requestCaseId: string }
    >({
      query: ({ requestCaseId }) => ({
        url: `/secured/request-case/${requestCaseId}/load-historical-expenses`,
        method: 'GET'
      }),
      keepUnusedDataFor: 0
    }),
    fetchRequestCaseWizard: build.query<
      RequestCaseWizardResponse,
      { requestCaseId: string }
    >({
      query: ({ requestCaseId }) => ({
        url: `/secured/request-case/${requestCaseId}/load-wizard`,
        method: 'GET'
      })
    }),
    submitRequestWizardStep4: build.mutation<
      RequestsWizardExpensesResponse,
      FormData
    >({
      query: (data) => {
        return {
          url: '/secured/request-case/submit-step-4',
          method: 'POST',
          data,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          formData: true
        };
      }
    })
  })
});

export const {
  useFetchSubmittedVesselPortQuery,
  useSaveRequestWizardStep1Mutation,
  useFetchHusbandyExpenseCataloguesQuery,
  useFetchSubmitedExpensesQuery,
  useSaveRequestWizardStep3Mutation,
  useFetchPastCaseExpensesQuery,
  useFetchRequestCaseWizardQuery,
  useSubmitRequestWizardStep4Mutation
} = buyerDaToolRequestWizardApi;
