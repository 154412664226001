import { mainRestApi } from '@state/stores/restApi/rest-api';

export const featureFlagsApi = mainRestApi.injectEndpoints({
  endpoints: (build) => ({
    fetchFeatureFlagStatus: build.query<Record<string, boolean>, void>({
      query: () => ({
        url: `/feature-flags`,
        method: 'GET'
      })
    })
  })
});

export const { useFetchFeatureFlagStatusQuery } = featureFlagsApi;
